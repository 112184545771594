.about-me {
  padding: 1em;
  margin: 1em 5%; /* Adjust this value as needed */
  max-width: 90%; /* Adjust this value as needed */
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  align-self: start;
}

.about-me-title {
    text-align: center;
    margin-bottom: 0.1em;
}

.about-me-description {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    /* border: 1px solid #ccc; */
    border-radius: 0.5em;
    padding: 0.5em;
    /* width: 90%; */
}