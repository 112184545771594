/* Styles for the projects__title element within projects block */
.experience__title{
    text-align: center;
    margin-bottom: 0.5em;
}

/* Styles for the projects__container element within projects block */
.projects__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: auto;
    padding: 0.5em;
}
