@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");
.App-header {
  font-size: 1rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  padding-bottom: 2rem;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%; /* Adjust this value as needed */
  height: 50vh; /* Adjust this value as needed */
  position: relative;
  border-radius: 10px;
  margin: auto;
  overflow: hidden;
}

.carousel img {
  width: 90%;
  height: 90%;
  object-fit: contain;
  overflow: hidden;
}
.slide_direction {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  background-color: #AEB5BF;
  color: #fff;
  padding: 10px 8px 8px 13px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.active {
  background-color: #CA6F40;
}

@media screen and (max-width: 640px) {
  .carousel {
    width: 100%;
  }

  .slide_direction {
    flex-direction: row;
    position: absolute;
    width: 100%;
    bottom: 0;
    justify-content: space-around;
  }

  .left,
  .right {
    top: unset;
    bottom: 0px; /* Adjust as needed */
  }
}